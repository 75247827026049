import { formValidators } from '@forms/validators';
import { getRegionForPhoneNumber, validatePhoneNumber } from '@helpers/phoneValidation';
import * as Yup from 'yup';

// Function to check if the provided country code matches the phone number's region
const isValidCountryCode = (countryCode, phoneNumber) => {
  const region = getRegionForPhoneNumber(phoneNumber);
  return typeof countryCode === 'string' && countryCode.length > 0 && region === countryCode;
};

Yup.addMethod(Yup.string, 'phone', function (strict = false, errorMessage = '') {
  const errMsg =
    typeof errorMessage === 'string' && errorMessage ? errorMessage : 'Ongeldig telefoonnummer';

  return this.test('phone', errMsg, function (value) {
    const { path, createError } = this;

    // Allow empty or partially completed phone numbers to pass validation
    if (!value || (value.startsWith('+') && value.length <= 5)) {
      return true;
    }

    // Check if the phone number's region matches the provided country code
    if (!isValidCountryCode(getRegionForPhoneNumber(value), value)) {
      return createError({ path, message: errMsg });
    }

    // Perform normal phone number validation
    const isValid = validatePhoneNumber(value, getRegionForPhoneNumber(value), strict);

    if (!isValid) {
      return createError({ path, message: errMsg });
    }

    return true;
  });
});

export const validationSchema = Yup.object().shape({
  email: formValidators.email,
  firstname: Yup.string().required('Voornaam is verplicht'),
  lastname: Yup.string().required('Achternaam is verplicht'),
  lastnamePrefix: Yup.string(),
  phoneNumber: Yup.string().phone(),
});
