import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone';
import 'react-international-phone/style.css';

const euCountryCodes = [
  'at',
  'be',
  'bg',
  'hr',
  'cy',
  'cz',
  'dk',
  'ee',
  'fi',
  'fr',
  'de',
  'gr',
  'hu',
  'ie',
  'it',
  'lv',
  'lt',
  'lu',
  'mt',
  'nl',
  'pl',
  'pt',
  'ro',
  'sk',
  'si',
  'es',
  'se',
  'gb',
  'no',
  'ch',
  'cw',
  'bq',
  'aw',
  'sx',
  'ad',
  'mc',
  'sm',
  'va',
];

const countryNameMap = {
  Austria: 'Oostenrijk',
  Belgium: 'België',
  Bulgaria: 'Bulgarije',
  Croatia: 'Kroatië',
  Cyprus: 'Cyprus',
  'Czech Republic': 'Tsjechië',
  Denmark: 'Denemarken',
  Estonia: 'Estland',
  Finland: 'Finland',
  France: 'Frankrijk',
  Germany: 'Duitsland',
  Greece: 'Griekenland',
  Hungary: 'Hongarije',
  Ireland: 'Ierland',
  Italy: 'Italië',
  Latvia: 'Letland',
  Lithuania: 'Litouwen',
  Luxembourg: 'Luxemburg',
  Malta: 'Malta',
  Netherlands: 'Nederland',
  Poland: 'Polen',
  Portugal: 'Portugal',
  Romania: 'Roemenië',
  Slovakia: 'Slovakije',
  Slovenia: 'Slovenië',
  Spain: 'Spanje',
  Sweden: 'Zweden',
  'United Kingdom': 'Verenigd Koninkrijk',
  Norway: 'Noorwegen',
  Switzerland: 'Zwitserland',
  Curacao: 'Curaçao',
  'Bonaire, Sint Eustatius, and Saba': 'Bonaire, Sint Eustatius en Saba',
  Aruba: 'Aruba',
  'Sint Maarten': 'Sint Maarten',
  Andorra: 'Andorra',
  Monaco: 'Monaco',
  'San Marino': 'San Marino',
  'Vatican City': 'Vaticaanstad',
};

let euCountries = defaultCountries
  .filter((country) => {
    const { iso2 } = parseCountry(country);
    return euCountryCodes.includes(iso2);
  })
  .map((countryArray) => {
    const englishName = countryArray[0];
    return [countryNameMap[englishName] || englishName, countryArray[1], countryArray[2]];
  });

export default function PhoneInputField({
  label,
  name,
  register,
  errors,
  message,
  onFocus,
  onBlur,
  onKeyDown,
  onKeyUp,
  value,
  isValid,
  customError,
  disabled,
  phoneInputProps,
  onChange,
  onInput,
}) {
  const inputProps = { onFocus, onBlur, onKeyDown, onKeyUp, value, disabled, onChange, onInput };

  Object.keys(inputProps).forEach((key) => inputProps[key] === undefined && delete inputProps[key]);

  const fieldErrors = (errors && errors[name]) || customError;

  return (
    <div className="relative z-10">
      <label
        htmlFor={name}
        className={classNames(
          'absolute -top-2 left-3 z-[60] inline-block bg-white px-1 font-primary text-xs font-light tracking-wide',
          fieldErrors
            ? 'text-functional-error'
            : isValid
              ? 'focus-within:text-green-500'
              : 'focus-within:text-accent',
          disabled ? 'opacity-50' : '',
        )}
      >
        {label}
      </label>
      <div className="w-full">
        <div className="flex items-center">
          <PhoneInput
            className="w-full"
            {...(register ? register(name) : {})}
            defaultCountry="nl"
            countries={euCountries}
            forceDialCode={true}
            inputProps={{
              id: name,
              ...inputProps,
              className: classNames(
                'block w-full rounded-r-md px-4 py-5 font-secondary text-sm tracking-wide text-primary-dark shadow-sm outline-none ring-1 ring-inset ring-[#E1E1E1] placeholder:text-primary-dark/30 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6',
                fieldErrors
                  ? 'ring-red-500 z-10'
                  : isValid
                    ? 'ring-green-500 z-10'
                    : 'focus:ring-accent',
                disabled ? 'bg-gray-100/20' : '',
                phoneInputProps.className,
              ),
              maxLength: 18,
            }}
            {...phoneInputProps}
            countrySelectorStyleProps={{
              buttonStyle: {
                height: '4rem',
                paddingTop: '1.25rem',
                paddingBottom: '1.25rem',
                paddingLeft: '1.5rem',
                paddingRight: '1rem',
                borderLeftWidth: '1px',
                borderTopWidth: '1px',
                borderBottomWidth: '1px',
                borderColor: '#E1E1E1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                borderTopLeftRadius: '0.375rem',
                borderBottomLeftRadius: '0.375rem',
              },
              flagStyle: { width: '24px', height: '64px' },
              dropdownStyleProps: {
                style: {
                  marginTop: '20px',
                  borderRadius: '0.375rem',
                },
              },
            }}
          />
          {isValid && !fieldErrors && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm">
              <Icon iconName="check" color="text-green-500" />
            </div>
          )}
        </div>
      </div>
      {fieldErrors && (
        <div className="mt-2 font-primary text-sm font-light tracking-wide text-functional-error">
          {message}
        </div>
      )}
    </div>
  );
}
