import { userStore } from '@context/userStore';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import Address from '@elements/forms/Address';
import AddressLookupResult from '@elements/forms/Address/AddressLookupResult';
import { classNames } from '@helpers/classNames';
import { getProfileAddresses, updateAddress } from '@providers/profile';
import { useEffect, useState } from 'react';

export default function AddressUpdateForm({ isOnboarding }) {
  const { profileId } = userStore();
  const [address, setAddress] = useState(null);
  const [billingFormData, setBillingFormData] = useState('');
  const [shippingFormData, setShippingFormData] = useState('');

  const [shippingAddress, setShippingAddress] = useState(null);
  const [showBillingAddressModal, setShowBillingAddressModal] = useState(false);
  const [showShippingAddressModal, setShowShippingAddressModal] = useState(false);

  const [isValid, setIsValid] = useState({});

  const getAddressData = async () => {
    const response = await getProfileAddresses();

    if (response && response.status === 200) {
      const billingData = response.data.find((address) => address.type === 'BILLING_ADDRESS');
      const shippingData = response.data.find((address) => address.type === 'SHIPPING_ADDRESS');

      if (billingData) {
        setBillingFormData(billingData.mail_lines);
        setAddress(billingData);
      }

      if (shippingData) {
        setShippingFormData(shippingData.mail_lines);
        setShippingAddress(shippingData);
      }
    }
  };

  const handleUpdateAddress = async (addressType, address) => {
    let response;

    const payload = {
      city: address.city,
      country: address.country,
      is_po_box: address.isPoBox,
      mail_lines: address.mailLines,
      number: Number(address.number),
      number_suffix: address.numberSuffix,
      postcode: address.postcode,
      street: address.street,
      type: address.type,
    };

    response = await updateAddress(profileId, payload);

    if (response && response.status === 200) {
      addressType === 'billing'
        ? setShowBillingAddressModal(false)
        : setShowShippingAddressModal(false);
      await getAddressData();
    }
  };

  useEffect(() => {
    let fetchData;
    fetchData = async () => {
      await getAddressData();
    };
    fetchData();
  }, [profileId]);

  const onClickBillingAddressModal = () => {
    setShowBillingAddressModal(!showBillingAddressModal);
  };

  const onClickShippingAddressModal = () => {
    setShowShippingAddressModal(!showShippingAddressModal);
  };

  return (
    <div className="container mx-auto pb-10 font-primary">
      <div className="flex justify-between">
        <div className="flex flex-col text-primary-dark">
          {!isOnboarding && <h2 className="font-primary text-3xl">Addressen</h2>}
          <p className={classNames('mb-8 mt-1 ', isOnboarding && 'font-light')}>
            Bekijk hier je adresgegevens.
          </p>
        </div>
      </div>

      <div className="rounded-md bg-white px-8 pb-12 pt-5 shadow-lg">
        <div className="flex items-center justify-between pb-4 pt-2">
          <h2 className="font-primary text-xl">Factuuradres</h2>
        </div>
        <div className="flex gap-8">
          {billingFormData ? (
            <div className="w-full max-w-lg divide-y divide-slate-200 rounded-lg bg-white shadow-lg">
              <AddressLookupResult
                country={address.country}
                mailLines={billingFormData}
                isBillingAddress={true}
              />
            </div>
          ) : (
            <p className="pt-2 font-light">Geen adresgegevens</p>
          )}

          <div className="mt-0.5">
            <Button
              type="button"
              icon={billingFormData ? 'pencil' : 'plus'}
              buttonType="secondary"
              onClick={onClickBillingAddressModal}
            />
          </div>
        </div>

        <Modal
          showModal={showBillingAddressModal}
          setShowModal={setShowBillingAddressModal}
          modalTitle="Wijzig mijn adres"
          modalContent={
            <div>
              <Address
                isBillingAddress={true}
                address={address}
                setAddress={setAddress}
                isValid={isValid}
                setIsValid={setIsValid}
              />
              <div className="flex justify-end gap-4 pt-4">
                <Button
                  label="Annuleren"
                  type="button"
                  buttonType="secondary"
                  onClick={() => setShowBillingAddressModal(false)}
                />
                <Button
                  label="Versturen"
                  type="button"
                  buttonType="primary"
                  onClick={async () => await handleUpdateAddress('billing', address)}
                  disabled={!isValid['address'] && (!isValid['postcode'] || !isValid['number'])}
                />
              </div>
            </div>
          }
        />

        {/* ShippingAddress */}
        <div className="flex items-center justify-between pb-4 pt-12">
          <h2 className="font-primary text-xl">Bezorgadres</h2>
        </div>
        <div className="flex gap-8">
          {shippingFormData ? (
            <div className="w-full max-w-lg divide-y divide-slate-200 rounded-lg bg-white shadow-lg">
              <AddressLookupResult
                country={shippingAddress.country}
                mailLines={shippingFormData}
                isBillingAddress={false}
              />
            </div>
          ) : (
            <p className="pt-2 font-light">Geen adresgegevens</p>
          )}
          <div className="mt-0.5">
            <Button
              type="button"
              icon={shippingFormData ? 'pencil' : 'plus'}
              buttonType="secondary"
              onClick={onClickShippingAddressModal}
            />
          </div>
        </div>

        <Modal
          showModal={showShippingAddressModal}
          setShowModal={setShowShippingAddressModal}
          modalTitle="Wijzig mijn bezorgadres"
          modalContent={
            <>
              <Address
                isBillingAddress={false}
                address={shippingAddress}
                setAddress={setShippingAddress}
                isValid={isValid}
                setIsValid={setIsValid}
              />
              <div className="flex justify-end gap-4 pt-4">
                <Button
                  label="Annuleren"
                  type="button"
                  buttonType="secondary"
                  onClick={() => setShowShippingAddressModal(false)}
                />
                <Button
                  label="Versturen"
                  type="button"
                  buttonType="primary"
                  onClick={async () => await handleUpdateAddress('shipping', shippingAddress)}
                  disabled={!isValid['address'] && (!isValid['postcode'] || !isValid['number'])}
                />
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}
