import * as Sentry from '@sentry/browser';
import { clientApi } from './clientApi';
import { serverSideApi } from './serverSideApi';

export async function getCategories() {
  try {
    return await clientApi('/category', 'GET', null);
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function getCategoryData(req, res, category) {
  try {
    return await serverSideApi(req, res, `/category/data?category=${category}`, 'GET');
  } catch (error) {
    Sentry.captureException(error);
  }
}
