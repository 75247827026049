import PropTypes from 'prop-types';

Detail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function Detail({ label, value }) {
  return (
    <li className="flex py-4 sm:gap-4 sm:px-4">
      <p className="size-full w-1/3 p-4 font-secondary text-primary-dark/70">{label}</p>
      <p className="size-full p-4 font-secondary text-primary-dark/70">{!value ? '-' : value}</p>
    </li>
  );
}

export default function DetailList({ details }) {
  return (
    <ul className="w-full divide-y divide-slate-200 rounded-lg bg-white shadow-lg">
      {Array.isArray(details) && details.length ? (
        details.map((detail, index) => (
          <Detail key={index} label={detail.label} value={detail.value} />
        ))
      ) : (
        //added this to remove the '-' i dont think it looks good.
        <Detail label="Geen adresgegevens" value=" " />
      )}
    </ul>
  );
}

DetailList.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
};
